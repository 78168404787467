import React from 'react';
import './user info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,TreeSelect,Image,Spin,Empty } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import Skeleton from 'react-loading-skeleton';
import Pagination from '@material-ui/lab/Pagination';

const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
class UserInfo extends React.Component {
    state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        user: this.props.location.state?this.props.location.state.data:[],
        fullname: this.props.location.state.data.fullname,
        email:this.props.location.state.data.email,
        type:this.props.location.state.data.type,
        oldType:this.props.location.state.data.type,
        phone:this.props.location.state.data.phone,
        accountType:this.props.location.state.data.accountType,
        isVerified:this.props.location.state.data.isVerified,
        // hasAdsPackage:false,
        // hasPropertyPackage:false,
        enableAsMarketer:false,
        enableSpecialAds:false,
        modal:false,
        reason:"",
        adsCount:0,
        requestCount:0,
        contactRequestCount:0,
        // completedAdsRequest:0,
        // unCompletedAdsRequest:0,
        availableAds:0,
        // adsPackage:null,
        approved:false,
        img:this.props.location.state?this.props.location.state.data.img !== ""?this.props.location.state.data.img:null:null,
        country:this.props.location.state.data.country?this.props.location.state.data.country.id:[],
        countryCode:'',
        city:this.props.location.state.data.city?this.props.location.state.data.city.id:null,
        commercialFile:null,
        fileType:"data",
        filePreviewName:[],
        countries:[],
        cities:[],
        loading:true,
        refresh:false,
        page:1,
        pages:1,
        imgType:'url',
        money:0,
        // fullDataAreas:[],
        // fullDataSubCategory:[],
        // subCategories:[],
        // categories:[],
        // areas:[],
        // workArea:null,
        // workCity:null,
        // workCategory:null,
        // workSubCategory:null,
        agencyType:'',
        imgName:'',
        requests:[],
        notApprovedReason:"",
        modal2:false,
        permissions:[],
        permission:null,
        // package:null,
        // packages:[],
        // durationType:'MONTHLY',
        modal3:false,
        authNumber:'',
        idNumber:'',
        adNumber:'',
        bio:'',
    };
      
    constructor(props){
      super(props)
      window.scrollTo(0,0)
      this.getUserData()
      this.getRequests()
      this.getCities(this.props.location.state.data.country?this.props.location.state.data.country.id:0)
      this.fileChangedHandler = this.fileChangedHandler.bind(this);
      /*if(this.state.type ==="OFFICE"){
        this.getAdsRequest()
        this.getCategory()
        this.getPackages()
      }*/
      if(this.state.type ==="SUB-ADMIN"){
        this.getPermissions()
      }
      this.getCountries()
      if(this.props.isRTL){
        allStrings.setLanguage('ar')
      }else{
        allStrings.setLanguage('en')
      } 
    }
    getUserData = () => {
      axios.get(`${BASE_END_POINT}${this.state.id}/getUser`,{
        headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        }})
      .then(response=>{
          console.log('theUser  ',response.data)
          this.setState({
            permission:response.data.permission?response.data.permission.id:[],
            adsCount:response.data.adsCount,
            accountType:response.data.accountType,
            loading:false,
            rate:response.data.rate?response.data.rate:0,
            //hasAdsPackage:response.data.hasAdsPackage?response.data.hasAdsPackage:false,
            approved:response.data.approved,
            isVerified:response.data.isVerified,
            //hasPropertyPackage:response.data.hasPropertyPackage?response.data.hasPropertyPackage:false,
            // notCompletedAdsRequest:response.data.notCompletedAdsRequest?response.data.notCompletedAdsRequest:0,
            // completedAdsRequest:response.data.completedAdsRequest?response.data.completedAdsRequest:0,
            phone:response.data.phone?response.data.phone:"",
            email:response.data.email?response.data.email:"",
            city:response.data.city?response.data.city.id:[],
            requestCount:response.data.requestCount,
            contactRequestCount:response.data.contactRequestCount,
            availableAds:response.data.availableAds,
            //adsPackage:response.data.adsPackage,
            agencyType:response.data.agencyType,
            authNumber:response.data.authNumber,
            idNumber:response.data.idNumber,
            adNumber:response.data.adNumber,
            bio:response.data.bio,
            enableAsMarketer:response.data.enableAsMarketer,
            enableSpecialAds:response.data.enableSpecialAds,
            commercialFile: response.data.commercialFile?response.data.commercialFile.length>0?response.data.commercialFile:null:null,
          })
          
          // let workCity=[];
          // let workArea=[];
          // let workCategory=[];
          // let workSubCategory=[];
          // if(this.state.type ==="OFFICE"){
          //   for (let v of response.data.workCity) {
          //     workCity.push(v.id)
          //   }
          //   for (let v of response.data.workArea) {
          //     workArea.push(v.id)
          //   }
          //   for (let v of response.data.workCategory) {
          //     workCategory.push(v.id)
          //   }
          //   for (let v of response.data.workSubCategory) {
          //     workSubCategory.push(v.id)
          //   }
          //   console.log(workCity)
          //   this.getAreas(workCity)
          //   this.getSubCategories(workCategory)
          // }
          // this.setState({workSubCategory: workSubCategory, workCategory: workCategory,workArea:workArea,workCity:workCity})

          this.getCountry(response.data.data.country?response.data.data.country.id:1)
      })
      .catch(error=>{
          console.log('user ERROR  ',error)
          console.log('user ERROR  ',error.respose)
      })
    }
    getPermissions = () => {
      axios.get(`${BASE_END_POINT}permission/withoutPagenation/get`)
      .then(response=>{
        console.log(response.data)
        this.setState({permissions:response.data.Permissions,loading:false})
      })
      .catch(error=>{
        console.log("ALL permissions ERROR")
        console.log(error.response)
      })
    }
    // getPackages = () => {
    //   axios.get(`${BASE_END_POINT}packages/withoutPagenation/get?all=true`)
    //   .then(response=>{
    //     console.log("packages",response.data)
    //     this.setState({packages:response.data.packages,loading:false})
    //   })
    //   .catch(error=>{
    //     console.log("ALL packages ERROR")
    //     console.log(error.response)
    //   })
    // }
    getRequests = (page) => {
      axios.get(`${BASE_END_POINT}request?page=1&user=${this.state.id}`,{
        headers:{
          'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        }
      })
      .then(response=>{
        console.log(response.data.data)
        this.setState({
          requests:response.data.data,
          page:response.data.page,
          pages:response.data.pageCount
        })
      })
      .catch(error=>{
        console.log("ALL ads ERROR")
        console.log(error.response)
      })
    }
    getCountry = (id) => {
      axios.get(`${BASE_END_POINT}countries/${id}`)
      .then(response=>{
        console.log("country",response.data.data)
        console.log("phone",this.state.phone.replace(response.data.data.countryCode, ""))
        this.setState({
          countryCode:response.data.data.countryCode,
          phone:this.state.phone.replace(response.data.data.countryCode, "")
        })
      })
      .catch(error=>{
        console.log(" country ERROR")
        console.log(error.response)
      })
    }
    getCities = (country) => {
      console.log(country)
      axios.get(`${BASE_END_POINT}countries/1/cities/withoutPagenation/get?country=${country}`)
      .then(response=>{
          console.log('cities  ',response.data)
          this.setState({cities:response.data.cities})
      })
      .catch(error=>{
          console.log('cities ERROR  ',error)
          console.log('cities ERROR  ',error.respose)
      })
    }
    // getAreas = (city) => {
    //   let arr = city
    //   console.log(arr)  
    //   let data =[]
    //     for(let i=0 ; i<= arr.length-1 ; i++){
    //       axios.get(`${BASE_END_POINT}areas/${arr[i]}/withoutPagenation/get`)
    //       .then(response=>{
            
    //         let results = response.data.areas.filter(({ id: id1 }) => !data.some(({ id: id2 }) => id2 === id1));
    //         console.log(results)
    //         data.push(...results)
    //         this.setState({fullDataAreas:[...results,...this.state.fullDataAreas]})
    //       })
    //       .catch(error=>{
    //         console.log(' areas ERROR  ',error)
    //         console.log('areas ERROR  ',error.respose)
    //       })
    //       this.setState({areas:data,fullDataAreas:[]})
    //     }
    // }
    // getCategory = () => {
    //   axios.get(`${BASE_END_POINT}categories`)
    //   .then(response=>{
    //       console.log('categories  ',response.data)
    //       this.setState({categories:response.data.categories})
    //   })
    //   .catch(error=>{
    //       console.log('categories ERROR  ',error)
    //       console.log('categories ERROR  ',error.respose)
    //   })
    // }
    // getSubCategories = (category) => {
    //   let arr = category
    //   console.log(arr)  
    //   let data =[]
    //     for(let i=0 ; i<= arr.length-1 ; i++){
    //       axios.get(`${BASE_END_POINT}categories/${arr[i]}/sub-categories`)
    //       .then(response=>{
            
    //         let results = response.data.categories.filter(({ id: id1 }) => !data.some(({ id: id2 }) => id2 === id1));
    //         console.log(results)
    //         data.push(...results)
    //         this.setState({fullDataSubCategory:[...results,...this.state.fullDataSubCategory]})
    //       })
    //       .catch(error=>{
    //         console.log(' sub category ERROR  ',error)
    //         console.log('sub category  ',error.respose)
    //       })
    //       this.setState({subCategories:data,fullDataSubCategory:[]})
    //     }
    // }
    componentDidMount(){
      if(this.props.isRTL){
        allStrings.setLanguage('ar')
      }else{
        allStrings.setLanguage('en')
      }
    }
    getCountries = () => {
      axios.get(`${BASE_END_POINT}countries/withoutPagenation/get`)
      .then(response=>{
        console.log(response.data)
        this.setState({countries:response.data.countries})
      })
      .catch(error=>{
        console.log("ALL countries ERROR")
        console.log(error.response)
      })
    }
    update = () => {
        const {img,city,fullname,phone,email,password,country,countryCode,permission,type,oldType,
         /* workArea,workCity,workCategory,workSubCategory,*/agencyType,commercialFile,fileType,bio,
          authNumber,adNumber,idNumber} = this.state
        let l = message.loading('Wait..', 2.5)
      
        if(hasValue(fullname)&&hasValue(email)&&hasValue(phone) ){
            
            console.log(countryCode)
            console.log("password",password)
            let data = new FormData()
            data.append('country',country)
            data.append('bio',bio)
            data.append('fullname',fullname)
            data.append('type',type)
            if(type ==="USER"){
              data.append('agencyType','INDIVIDUAL')
            }
            if(type ==="OFFICE"){
              if(oldType !== "OFFICE"){
                data.append('accountType','SIGNUP-PROCESS')
              }
              data.append('agencyType',agencyType)
              data.append('authNumber',authNumber)
              data.append('adNumber',adNumber)
              data.append('idNumber',idNumber)
            }
            if(type ==="SUB-ADMIN"){
              data.append('permission',permission)
            }
            data.append('email',email)
            if(password !== '' && password !== undefined){
              data.append('password',password)
            }
            if(countryCode ==="+20"){
              console.log("EG")
              let thePhone = countryCode+phone
              data.append('phone',thePhone.replace('+200','+20'))
            }
            if(countryCode ==="+966"){
              let thePhone = countryCode+phone
              data.append('phone',thePhone.replace('+9660','+966'))
            }else{
              data.append('phone',countryCode+phone)
            }
            if(city != null){
              data.append('city',city)
            }
            if(commercialFile != null && fileType){
              for(let i=0 ; i<= commercialFile.length-1 ; i++){
                data.append(`commercialFile`,commercialFile[i] )
              } 
            }
            // if(workCity !=null)
            //   data.append('workCity',JSON.stringify(workCity))
            // if(workArea !=null)
            //   data.append('workArea',JSON.stringify(workArea))
            // if(workCategory !=null)
            //   data.append('workCategory',JSON.stringify(workCategory))
            // if(workSubCategory !=null)
            //   data.append('workSubCategory',JSON.stringify(workSubCategory))
            if(img != null){
                data.append('img',img)
            }
            
            console.log(Array.from(data))
            
            axios.put(`${BASE_END_POINT}user/${this.state.id}/updateInfo`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            l.then(() => message.success(allStrings.done, 2.5) )
              this.props.history.goBack()
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    submitHandler = event => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.update()
    };
  
    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    
    fileChangedHandler = event => {
      this.setState({
        img: event.target.files[0],
        imgName: event.target.files[0].name,
        imgType:"data"
      })
      
    }
    removeFile = () => {
      this.setState({
          img:null,
          imgName:'',
          imgType:"url"
      });
    }
    
    imageSection = () =>{
        return(
          <MDBCol  xl ="4" lg = "5"  md="12" sm="12">
          <div className="infoCard">
              <div style={{width:"100%" ,padding:'10px'}}>
              <Image
                width="100%"
                placeholder={true}
                style={{borderRadius:'6px',width:"100%",height:'280px'}} 
                src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.props.location.state.data.img}
              />
              </div>
          </div>
          
      </MDBCol>
        )
    }
    buttonsSection = () =>{
      return(
        <div className="btnOption">
          <MDBRow>
            <MDBCol md="6">
              <MDBBtn
                color="red"
                rounded
                style={{ borderRadius: "2px"}}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.delete()
                }}
                >
                <MDBIcon icon="trash-alt" className="mr-2" />
                    {allStrings.remove}
              </MDBBtn>
            </MDBCol>
            {this.state.accountType === "ACTIVE"?
            <MDBCol md="6">
            <MDBBtn
              rounded
              color="warning"
              style={{ borderRadius: "2px" }}
              className="btn-block z-depth-1a"
              onClick={()=>{
                this.active(false)
              }}
            >
              <MDBIcon icon="ban" className="mr-2" />
              {allStrings.disActive}
            </MDBBtn>
            </MDBCol>
            :
            
            <MDBCol md="6">
            <MDBBtn
              rounded
              color="info"
              style={{ borderRadius: "2px" }}
              className="btn-block z-depth-1a"
              onClick={()=>{
                this.active(true)
              }}
            >
              <MDBIcon icon="check-circle" className="mr-2" />
              {allStrings.active}
            </MDBBtn>
          </MDBCol>
            }
          </MDBRow>
          {this.state.type ==="OFFICE"&&
          <MDBRow>
            {!this.state.approved?
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="success"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.approved(true)
                }}
              >
                <MDBIcon icon="check-circle" className="mr-2" />
                {allStrings.approved}
              </MDBBtn>
            </MDBCol>
            :
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="danger"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={this.toggle2} 
              >
                <MDBIcon icon="ban" className="mr-2" />
                {allStrings.disApproved}
              </MDBBtn>
            </MDBCol>
            }
            {!this.state.enableSpecialAds?
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="success"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.specialAds(true)
                }}
              >
                <MDBIcon icon="check-circle" className="mr-2" />
                {allStrings.enableSpecialAds}
              </MDBBtn>
            </MDBCol>
            :
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="danger"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.specialAds(false)
                }}
              >
                <MDBIcon icon="ban" className="mr-2" />
                {allStrings.disableSpecialAds}
              </MDBBtn>
            </MDBCol>
            }
            {!this.state.enableAsMarketer?
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="success"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.asMarketer(true)
                }}
              >
                <MDBIcon icon="check-circle" className="mr-2" />
                {allStrings.enableAsMarketer}
              </MDBBtn>
            </MDBCol>
            :
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="danger"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.asMarketer(false)
                }}
              >
                <MDBIcon icon="ban" className="mr-2" />
                {allStrings.disableAsMarketer}
              </MDBBtn>
            </MDBCol>
            }
          </MDBRow>
          }
          
        </div>
      )
    }
    delete = () => {
      let l = message.loading('Wait..', 2.5)
      axios.delete(`${BASE_END_POINT}${this.state.id}/delete`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      },
      })
      .then(response=>{
        console.log("USER DELETED")
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack()
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    // buyPackage = () => {
    //   let l = message.loading('Wait..', 2.5)
    //   let data = {
    //     "durationType":this.state.durationType,
    //     "duration":1,
    //     "package":this.state.package
    //   }
    //   axios.post(`${BASE_END_POINT}packages/${this.state.id}/buyPackage`,data,{
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${this.props.currentUser.token}`,
    //     'Accept-Language':this.props.isRTL?'ar':'en'
    //   },
    //   })
    //   .then(response=>{
    //     l.then(() => message.success(allStrings.done, 2.5) )
    //     this.toggle3()
    //   })
    //   .catch(error=>{
    //       console.log(error.response)
    //       l.then(() => message.error(allStrings.error, 2.5))
    //   })
    // }
    // removeAdsPackage = () => {
    //   let l = message.loading('Wait..', 2.5)
    //   axios.put(`${BASE_END_POINT}packages/${this.state.id}/endAdsPackage`,{},{
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${this.props.currentUser.token}`,
    //     'Accept-Language':this.props.isRTL?'ar':'en'
    //   },
    //   })
    //   .then(response=>{
    //     l.then(() => message.success(allStrings.done, 2.5) )
    //   })
    //   .catch(error=>{
    //       console.log(error.response)
    //       l.then(() => message.error(allStrings.error, 2.5))
    //   })
    // }
    // removePropertyPackage = () => {
    //   let l = message.loading('Wait..', 2.5)
    //   axios.put(`${BASE_END_POINT}packages/${this.state.id}/endPropertyPackage`,{},{
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${this.props.currentUser.token}`,
    //     'Accept-Language':this.props.isRTL?'ar':'en'
    //   },
    //   })
    //   .then(response=>{
    //     l.then(() => message.success(allStrings.done, 2.5) )
    //   })
    //   .catch(error=>{
    //       console.log(error.response)
    //       l.then(() => message.error(allStrings.error, 2.5))
    //   })
    // }
    // specialAds = (enable) => {
    //   let uri ='';
    //   if(enable){
    //     uri = `${BASE_END_POINT}${this.state.id}/enableSpecialAds`
    //   }else{
    //     uri = `${BASE_END_POINT}${this.state.id}/disableSpecialAds`
    //   }
    //   let l = message
    //   .loading('Wait..', 2.5)
    //   axios.put(uri,{},{
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': `Bearer ${this.props.currentUser.token}`,
    //       'Accept-Language':this.props.isRTL?'ar':'en'
    //     },
    //   })
    //   .then(response=>{
    //         l.then(() => message.success(allStrings.done, 2.5)) 
    //         this.props.history.goBack()
    //   })
    //   .catch(error=>{
    //     console.log('Error   ',error.response)
    //     l.then(() => message.error(allStrings.error, 2.5))
    //   })
    // }
    asMarketer = (enableAsMarketer) => {
      let uri ='';
      if(enableAsMarketer){
        uri = `${BASE_END_POINT}${this.state.id}/enableAsMarketer`
      }else{
        uri = `${BASE_END_POINT}${this.state.id}/disableAsMarketer`
      }
      let l = message
      .loading('Wait..', 2.5)
      axios.put(uri,{},{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
      })
      .then(response=>{
            if(enableAsMarketer){
                l.then(() => message.success(allStrings.done, 2.5))
                this.setState({enableAsMarketer:true})
            }else{
              l.then(() => message.success(allStrings.done, 2.5))
              this.setState({enableAsMarketer:false})
            }
      })
      .catch(error=>{
        console.log('Error   ',error.response)
        l.then(() => message.error(allStrings.error, 2.5))
      })
    }
      
    active = (active) => {
      let uri ='';
      if(active){
        uri = `${BASE_END_POINT}${this.state.id}/active`
      }else{
        uri = `${BASE_END_POINT}${this.state.id}/dis-active`
      }
      let l = message
      .loading('Wait..', 2.5)
      axios.put(uri,{},{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
      })
      .then(response=>{
            if(active){
                l.then(() => message.success(allStrings.done, 2.5))
                this.setState({active:true})
            }else{
              l.then(() => message.success(allStrings.done, 2.5))
              this.setState({active:false})
            }
            this.props.history.goBack()
      })
      .catch(error=>{
        console.log('Error   ',error.response)
        l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    approved = (approved) => {
      let uri ='';
      let data={}
      if(approved){
       uri = `${BASE_END_POINT}${this.state.id}/approved`
      }else{
        data.reason = this.state.notApprovedReason
       uri = `${BASE_END_POINT}${this.state.id}/dis-approved`
      }
     let l = message
      .loading('Wait..', 2.5)
      axios.put(uri,data,{
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${this.props.currentUser.token}`,
         'Accept-Language':this.props.isRTL?'ar':'en'
       },
     })
      .then(response=>{
           if(approved){
               l.then(() => message.success(allStrings.done, 2.5))
               this.setState({approved:true})
           }else{
              l.then(() => message.success(allStrings.done, 2.5))
              this.setState({approved:false})
              this.toggle2()
           }
           this.props.history.goBack()
      })
      .catch(error=>{
       console.log('Error   ',error.response)
       l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    fileChangedHandler2 = event => {
      if(event.target.files[0]){
        let filePreviewName = []
        filePreviewName.push(event.target.files[0].name)
        let imgs = [];
        imgs.push(event.target.files[0])
        this.setState({
          commercialFile: imgs,
          fileType:"data",
          filePreviewName:filePreviewName
        })
      }
    }
    removeFile2 = () => {
      this.setState({
        commercialFile:null,
        fileType:"url",
        filePreviewName:[]
      });
    }
    submitHandlerDisApproved = (event) => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.approved(false)
    };
    changeHandlerDisApproved = (event) => {
        console.log(event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
    toggle2 = () => {
      console.log(this.state.modal2)
      this.setState({
        modal2: !this.state.modal2,
      });
    }
    modal2 = () =>(
      <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="lg">
        <MDBModalHeader toggle={this.toggle2} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerDisApproved}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                        <MDBCol md="12">
                          <div className="md-form">
                            <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.reason}</label>
                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form92" className="form-control" onChange={this.changeHandlerDisApproved} required validate  name="notApprovedReason"/>
                            <div class="valid-feedback">{allStrings.correct}</div>
                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#00B483' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon className="mr-2" />
                        {allStrings.disApproved}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
    paginationButtons = () =>(
      <CardActions style={{display: 'inline-flex'}}>
          <Button 
          onClick={()=>{
            if(this.state.page!==1){
              this.getAdsRequest(1)
              this.setState({page:1})
            }
          }}
          className="pagenation" >{allStrings.first}</Button>
  
          <Button 
          onClick={()=>{
            if(this.state.page>1){
              this.getAdsRequest(this.state.page-1)
              this.setState({page:this.state.page-1})
            }
          }}
          className="pagenation">{allStrings.prev}</Button>
          
          <Pagination
          onChange={(event,page)=>{
          console.log("page   ",page)
          if(page!==this.state.page){
            this.getAdsRequest(page)
            this.setState({page:page})
          }
          
          }} 
          defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`${'#00B483'} !important`}} />
          
          <Button 
          onClick={()=>{
            if(this.state.page<this.state.pages){
              this.getAdsRequest(this.state.page+1)
              this.setState({page:this.state.page+1})
            }
          }}
          className="pagenation">{allStrings.next}</Button>
  
          <Button 
            onClick={()=>{
            if(this.state.page!==this.state.pages){
              this.getAdsRequest(this.state.pages)
              this.setState({page:this.state.pages})
            }
          }}
          className="pagenation">{allStrings.last}</Button>
  
      </CardActions>
    )
    requestsTable = () =>{
      return(
      <MDBRow>
          <MDBCol md="12">
              <div className="infoTable">
                  <div className="topInfoTable" style={{ background: '#00B483' }}>
                      <h4>
                      <span class="material-icons" style={{color:'white',fontSize:23}}>featured_video</span> {allStrings.requests}
                      </h4>
                  </div>
                  <div className="tableDate">
                      <div className="table-responsive">
                          <table className="table table-striped">
                          <thead>
                            {this.props.isRTL?
                              <tr style={{textAlign:'center'}}>
                                  <th>{allStrings.more}</th>
                                  <th scope="col">{allStrings.status}</th>
                                  <th scope="col">{allStrings.city}</th>
                                  <th scope="col">{allStrings.category}</th>
                                  <th scope="col">#</th>
                              </tr>
                                  :
                              <tr style={{textAlign:'center'}}>
                                  <th scope="col">#</th>
                                  <th scope="col">{allStrings.category}</th>
                                  <th scope="col">{allStrings.city}</th>
                                  <th scope="col">{allStrings.status}</th>
                                  <th>{allStrings.more}</th>
                              </tr>
                            }
                                
                          </thead>
                          <tbody>
                          {this.state.loading?
                              <tr style={{height: '200px'}}>
                                  <th colSpan="6" className="noData">
                                  <Skeleton height={400}/> 
                                  </th>
                              </tr>
                              :
                              this.state.requests.length > 0 ?
                              this.state.requests.map(val =>(
                                this.props.isRTL?
                                <tr style={{textAlign:'center'}}>
                                    <td onClick={()=>{this.props.history.push("requestInfo",{data:val}) }} >
                                    <span style={{margin: "4px",cursor:'pointer'}}>
                                        <Tooltip title={allStrings.info} placement="top">
                                        <Info style={{color:'#33d633'}}/>
                                        </Tooltip>
                                    </span>
                                    </td>
                                    <th>{val.status==="NEW"?allStrings.new:val.status==="COMPLETED"?allStrings.completed:val.status==="FAILED"?allStrings.failed:allStrings.onProgress}</th>
                                    <td>{val.city.cityName}</td>
                                    <th>{val.category.categoryName}</th>
                                    <td>{val.id}</td>
                                </tr>
                                :
                                <tr style={{textAlign:'center'}}>
                                    <td>{val.id}</td>
                                    <th>{val.category.categoryName}</th>
                                    <td>{val.city.cityName}</td>
                                    <th>{val.status==="NEW"?allStrings.new:val.status==="COMPLETED"?allStrings.completed:val.status==="FAILED"?allStrings.failed:allStrings.onProgress}</th>
                                    <td onClick={()=>{this.props.history.push("requestInfo",{data:val}) }} >
                                    <span style={{margin: "4px",cursor:'pointer'}}>
                                        <Tooltip title={allStrings.info} placement="top">
                                        <Info style={{color:'#33d633'}}/>
                                        </Tooltip>
                                    </span>
                                    </td>
                                </tr>
                                  ))
              
                                  :
                                  <tr style={{height: '200px'}}>
                                      <th colSpan="6" className="noData">
                                      <Empty description={allStrings.noData} style={{margin:'0rem'}}/>
                                      </th>
                                  </tr>
                              }
                          </tbody>
                          </table>
                          {this.state.requests.length > 0 &&
                              <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                                  {this.paginationButtons()}
                              </div>
                          }
                      </div>
                  </div>
              </div>
          </MDBCol>
      </MDBRow>
      )
    }
    // submitHandlerAddPackage = (event) => {
    //   event.preventDefault();
    //   event.target.className += " was-validated";
    //   this.buyPackage()
    // };
    // changeHandlerAddPackage = (event) => {
    //     console.log(event.target.value);
    //     this.setState({ [event.target.name]: event.target.value });
    // };
    // toggle3 = () => {
    //   console.log(this.state.modal3)
    //   this.setState({
    //     modal3: !this.state.modal3,
    //   });
    // }
    // modal3 = () =>(
    //   <MDBModal isOpen={this.state.modal3} toggle={this.toggle3} size="lg">
    //     <MDBModalHeader toggle={this.toggle3} >{allStrings.completeData}</MDBModalHeader>
    //       <MDBModalBody>
    //         <MDBContainer>
    //           <MDBRow>
    //             <MDBCol md="12">
    //               <form style={{paddingTop:'1rem'}}
    //                 className="needs-validation"
    //                 onSubmit={this.submitHandlerAddPackage}
    //                 noValidate
    //               >
    //                 <div className="grey-text">
    //                   <MDBRow>
    //                     <MDBCol md="6">
    //                         <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.durationType}</label>
    //                         <TreeSelect virtual={false}
    //                             showSearch={false} 
    //                             value={this.state.durationType}
    //                             style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
    //                             treeCheckable = {false}         
    //                             treeNodeFilterProp="title"                      
    //                             dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
    //                             placeholder={allStrings.durationType}
    //                             onChange={(value) => {
    //                             console.log(value)
    //                             this.setState({durationType:value});
    //                             }} 
                            
    //                         >
    //                             {/*<TreeNode value="DAILY" title={allStrings.daily} key="DAILY" />*/}
    //                             <TreeNode value="MONTHLY" title={allStrings.monthly} key="MONTHLY" />
    //                             <TreeNode value="YEARLY" title={allStrings.yearly} key="YEARLY" />
                                
    //                         </TreeSelect>
    //                     </MDBCol>
    //                     <MDBCol md="6">
    //                       <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.package}</label>
    //                       <TreeSelect virtual={false}
    //                           showSearch={false}
    //                           style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
    //                           treeCheckable = {false}         
    //                           treeNodeFilterProp="title"                      
    //                           dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
    //                           placeholder={allStrings.package}
    //                           value={this.state.package}
    //                           showCheckedStrategy= {SHOW_PARENT}
    //                           onChange={(value) => {
    //                           console.log(value)
    //                           this.setState({package:value});
                              
    //                           }} 
                          
    //                       >
    //                           {this.state.packages.map(val=>(
    //                               <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
    //                           ))}
    //                       </TreeSelect>
    //                     </MDBCol>
    //                   </MDBRow>
    //                 </div>
    //                 <br></br>
    //                 <br></br>
    //                 <div className="text-center">
    //                   <MDBBtn type="submit" style={{ background: '#00B483' }} rounded className="btn-block z-depth-1a" >
    //                     <MDBIcon className="mr-2" />
    //                     {allStrings.add}
    //                   </MDBBtn>
    //                 </div>
    //               </form>
    //             </MDBCol>
    //           </MDBRow>
    //         </MDBContainer>
    //     </MDBModalBody>
    //   </MDBModal>
    // );
  render(){
  return (
    <div className="App">
     
      <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#00B483'}}>
        <MDBContainer>
        {this.modal2()}
        {/* {this.modal3()} */}
            <MDBRow>
                <MDBCol xl={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">

                    <div className="infoCard">
                        <Card className="editCard" style={{overflow: 'visible',outline:'none',marginBottom:'0px'}}>
                       
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon" style={{background:'#00B483'}}>
                                <span className="material-icons" style={{color:'white',fontSize:23}}>group</span>
                            </div>
                            <div className="tableTitle" style={{display:'inline-block',marginTop:'-60px',
                            marginLeft:'0',marginRight:'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.userProfile:allStrings.edit}</p>
                            </div>
                        </div>
                        {this.state.type ==="OFFICE"&&this.state.actionType==="VIEW"&&
                          <div style={{width:'100%',display:'inline-block'}}>
                              <Rater style={{float:this.props.isRTL? 'left':'right',marginRight:'20px',marginLeft:'20px'}} rating={this.state.rate} total={5} interactive={false}/>
                          </div>
                        }
                        
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    <div className="grey-text">
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.userType}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user.type === "ADMIN"?allStrings.admin:this.state.user.type==="OFFICE"?allStrings.office:this.state.type==="SUB-ADMIN"?allStrings.subAdmin:allStrings.client} type="text" id="form8" className="form-control" validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control"  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                       
                                    </MDBRow>
                                    }{this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form645" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.approved}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.approved===true?allStrings.yes:allStrings.no} type="text" id="form645" name="approved" className="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form5" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.accountType}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.accountType==="ACTIVE"?allStrings.active:this.state.accountType==="BLOCKED"?allStrings.blocked:allStrings.notActive} type="text" id="form5" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form645" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.enableSpecialAds}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.enableSpecialAds===true?allStrings.yes:allStrings.no} type="text" id="form645" name="approved" className="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form5" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.enableAsMarketer}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.enableAsMarketer===true?allStrings.yes:allStrings.no} type="text" id="form5" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.type ==="ADMIN" || this.state.type ==="SUB-ADMIN" ||
                                    <MDBRow>
                                      {this.state.actionType==="VIEW"&&
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form05" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.requestsCount}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.requestsCount} type="text" id="form05" name="requestsCount" className="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                      }{this.state.actionType==="VIEW"&&
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form005" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.contactRequestCount}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.contactRequestCount} type="text" id="form005" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        }
                                    </MDBRow>
                                    }{this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form0005" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.availableAds}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.availableAds} type="text" id="form0005" name="availableAds" className="form-control" onChange={this.changeHandler}  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form308" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.isVerified}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.isVerified===true?allStrings.yes:allStrings.no} type="text" id="form308" className="form-control"  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    <MDBRow>
                                    {this.state.type ==="USER" || this.state.type ==="OFFICE" ?
                                        <MDBCol md="6">
                                          {this.state.actionType==="VIEW"&&
                                            <div className="md-form">
                                            <label htmlFor="form38" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.adsCount}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.adsCount} type="text" id="form38" className="form-control"  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                            }
                                        </MDBCol>
                                        :
                                        this.state.type ==="SUB-ADMIN"?
                                        <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.permission}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.permission?this.state.permission:[]}
                                              showSearch={true} 
                                              disabled={this.state.actionType==="VIEW"?true:false}
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {false}         
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.permission}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({permission:value});
                                              }} 
                                          
                                          >
                                              {this.state.permissions.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.permission_ar:val.permission_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                         </MDBCol>
                                        :
                                        <MDBCol md="6"></MDBCol>
                                    }
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.name}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.fullname} type="text" id="form645" name="fullname" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.email}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?/&#^-_. +]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.email} type="email" id="form65" name="email" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form615" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.phone} type="text" id="form615" name="phone" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        
                                    </MDBRow>
                                    

                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.country}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.country}
                                              disabled={this.state.actionType==="VIEW"?true:false}
                                              showSearch={true} 
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {false}         
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.country}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({country:value});
                                              this.getCities(value)
                                              this.getCountry(value)
                                              }} 
                                          
                                          >
                                              {this.state.countries.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.countryName_ar:val.countryName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.city}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.city?this.state.city:[]}
                                              showSearch={true} 
                                              disabled={this.state.actionType==="VIEW"?true:false}
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {false}         
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.city}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({city:value});
                                              }} 
                                          
                                          >
                                              {this.state.cities.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.cityName_ar:val.cityName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                    </MDBRow>
                                    {/*this.state.type ==="OFFICE"&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.workCity}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.workCity?this.state.workCity:[]}
                                              showSearch={true} 
                                              disabled={this.state.actionType==="VIEW"?true:false}
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {true}        
                                              showCheckedStrategy= {SHOW_PARENT}  
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.workCity}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({workCity:value,workArea:[]});
                                              this.getAreas(value)
                                              }} 
                                          
                                          >
                                              {this.state.cities.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.cityName_ar:val.cityName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.workArea}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.workArea?this.state.workArea:[]}
                                              showSearch={true} 
                                              disabled={this.state.actionType==="VIEW"?true:false}
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {true}    
                                              showCheckedStrategy= {SHOW_PARENT}       
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.workArea}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({workArea:value});
                                              }} 
                                          
                                          >
                                              {this.state.areas.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.areaName_ar:val.areaName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                    </MDBRow>
                                    }{this.state.type ==="OFFICE"&&
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.workCategory}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.workCategory?this.state.workCategory:[]}
                                              showSearch={true} 
                                              disabled={this.state.actionType==="VIEW"?true:false}
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {true}        
                                              showCheckedStrategy= {SHOW_PARENT}  
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.workCategory}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({workCategory:value,workSubCategory:[]});
                                              this.getSubCategories(value)
                                              }} 
                                          
                                          >
                                              {this.state.categories.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.workSubCategory}</label>
                                          <TreeSelect virtual={false}
                                              value={this.state.workSubCategory?this.state.workSubCategory:[]}
                                              showSearch={true} 
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {true} 
                                              disabled={this.state.actionType==="VIEW"?true:false}  
                                              showCheckedStrategy= {SHOW_PARENT}        
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.workSubCategory}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({workSubCategory:value});
                                              }} 
                                          
                                          >
                                              {this.state.subCategories.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                    </MDBRow>
                                    */}
                                    {this.state.type ==="OFFICE"&& 
                                    <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                      <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.agencyType}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                disabled={this.state.actionType==="VIEW"?true:false}
                                                value={this.state.agencyType}
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.agencyType}
                                                onChange={(value) => {
                                                console.log(value)
                                                this.setState({agencyType:value});
                                                }} 
                                            
                                            >
                                              
                                                <TreeNode value="COMPANY" title={allStrings.company} key="COMPANY" />
                                                <TreeNode value="OFFICE" title={allStrings.office} key="OFFICE" />
                                                <TreeNode value="MARKETER" title={allStrings.marketer} key="MARKETER" />
                                                <TreeNode value="FREEWORK" title={allStrings.freeWork} key="FREEWORK" /> 
                                                
                                            </TreeSelect>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                            <label htmlFor="formn005" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.idNumber}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.idNumber} type="text" id="formn005" name="idNumber" className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                    </MDBRow>
                                    }
                                    {/* {this.state.type ==="OFFICE"&&this.state.actionType==="VIEW"&&
                                      <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="formn005" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.hasPropertyPackage}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.hasPropertyPackage} type="text" id="formn005" name="hasPropertyPackage" className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                          <div className="md-form">
                                            <label htmlFor="formn8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.hasAdsPackage}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.hasAdsPackage} type="text" id="formn8" className="form-control"  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="formn08" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.completedAdsRequest}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.completedAdsRequest} type="text" id="formn08" className="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol> 
                                    </MDBRow>
                                    } */}
                                    {/* {this.state.type ==="OFFICE"&&
                                      <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="formm08" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.requests}</label>
                                            <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.notCompletedAdsRequest} type="text" id="formm08" className="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol> 
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="formn005" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.idNumber}</label>
                                            <input  disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.idNumber} type="text" id="formn005" name="idNumber" className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    } */}
                                    {this.state.type ==="OFFICE"&&
                                      <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="formn005" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.adNumber}</label>
                                            <input  disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} onChange={this.changeHandler} value={this.state.adNumber} type="text" id="formn005" name="adNumber" className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="formn08" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.authNumber}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} onChange={this.changeHandler} value={this.state.authNumber} type="text" id="formn08" name="authNumber" className="form-control"  />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.actionType==="EDIT"&&
                                    <MDBRow>
                                      <MDBCol md="6">
                                          <div className="md-form">
                                          <label htmlFor="form108" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.password}</label>
                                          <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form108" className="form-control" name="password" onChange={this.changeHandler} validate/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                          </div>
                                      </MDBCol>
                                      <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.userType}</label>
                                          <TreeSelect virtual={false}
                                              showSearch={false} 
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {false}         
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.type}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({type:value});
                                              // if(value ==="OFFICE"){
                                              //   this.getCategory()
                                              // }
                                              if(value ==="SUB-ADMIN"){
                                                this.getPermissions()
                                              }

                                              }} 
                                          
                                          >
                                              <TreeNode value="ADMIN" title={allStrings.admin} key="ADMIN" />
                                              <TreeNode value="SUB-ADMIN" title={allStrings.subAdmin} key="SUB-ADMIN" />
                                              <TreeNode value="USER" title={allStrings.client} key="USER" />
                                              <TreeNode value="OFFICE" title={allStrings.office} key="OFFICE" />
                                              
                                          </TreeSelect>
                                      </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.type ==="OFFICE"&&
                                    <MDBRow>
                                      <MDBCol md="12">
                                        <div className="md-form">
                                          <label htmlFor="form092" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.bio}</label>
                                          <input disabled={this.state.actionType==="VIEW"?true:false} value={this.state.bio}  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form092" className="form-control"  name="bio"/>
                                          <div class="valid-feedback">{allStrings.correct}</div>
                                          <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                        </div>
                                      </MDBCol>
                                    </MDBRow>
                                    }
                                    {this.state.type ==="OFFICE"&&
                                    <MDBRow>
                                      {this.state.actionType==="EDIT"&&
                                        <MDBCol md="12">
                                        <label htmlFor="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.files}</label>

                                            <div className="fileUpload2"> 
                                                <input mu  key={this.state.commercialFile} type='file' className="upImgs2" onChange={this.fileChangedHandler2} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.uploadFile}</span></span>
                                            </div>
                                            <div className="preview">
                                                {this.state.filePreviewName.map((val) => (
                                                <div style={{ display: "inline-block" }}>
                                                <p className="previewPdf">{val}
                                                <MDBIcon icon="times" className="mr-2 removePdf"
                                                    onClick={() => this.removeFile2()}  ></MDBIcon>
                                                </p>
                                                </div>
                                                ))}
                                            
                                            </div>
                                        </MDBCol>
                                      }
                                        {this.state.commercialFile != null &&
                                            <div>
                                                {this.state.commercialFile.map((val) => (
                                                <a href={val} target="blank" style={{background:'#00B483',
                                                color: '#fff',
                                                padding: '4px 40px 6px',
                                                marginLeft: '15px',
                                                fontFamily: 'fantasy',
                                                fontSize: '13px'}}
                                                >
                                                    {allStrings.openFile}          
                                                </a>
                                                ))}
                                            </div>
                                        }
                                        
                                        
                                    </MDBRow>
                                    }{this.state.actionType==="EDIT"&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                        <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.profileImg}</label>

                                            <div className="fileUpload2"> 
                                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.img&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img} className="previewImg2"/>
                                                
                                                <span className="imgName">{this.state.imgName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg3"
                                                onClick={() => this.removeFile()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                            
                                        </MDBCol>
                                    </MDBRow>
                                    }

                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                    {this.state.actionType==="EDIT"&&
                                      <MDBBtn
                                      type="submit" style={{background:'#00B483'}}
                                      rounded
                                      className="btn-block z-depth-1a"
                                      >
                                          <MDBIcon icon="edit" className="mr-2" />
                                          {allStrings.update}        
                                      </MDBBtn>
                                    }
                                    {/* {this.state.type ==="OFFICE"&&this.state.actionType==="VIEW"&&
                                      <MDBBtn
                                        color="#00B483"
                                        rounded
                                        style={{ borderRadius: "2px",background:'#00B483' ,color: '#fff'}}
                                        className="btn-block z-depth-1a"
                                        onClick={()=>{
                                            this.toggle3()
                                        }}
                                      >
                                        {allStrings.AddPackage}
                                      </MDBBtn>
                                    } */}
                                    {/* {this.state.type ==="OFFICE"&&this.state.actionType==="VIEW"&&this.state.hasAdsPackage ===true&&
                                      <MDBBtn
                                        color="red"
                                        rounded
                                        style={{ borderRadius: "2px",background:'red' ,color: '#fff'}}
                                        className="btn-block z-depth-1a"
                                        onClick={()=>{
                                            this.removeAdsPackage()
                                        }}
                                      >
                                        {allStrings.removeAdsPackage}
                                      </MDBBtn>
                                    } */}
                                    {/* {this.state.type ==="OFFICE"&&this.state.actionType==="VIEW"&&this.state.hasPropertyPackage ===true&&
                                      <MDBBtn
                                        color="red"
                                        rounded
                                        style={{ borderRadius: "2px",background:'red' ,color: '#fff'}}
                                        className="btn-block z-depth-1a"
                                        onClick={()=>{
                                            this.removePropertyPackage()
                                        }}
                                      >
                                        {allStrings.removePropertyPackage}
                                      </MDBBtn>
                                    } */}
                                      
                                  </div>
                                </form>
                                </MDBCol>
                                
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            {this.state.actionType==="VIEW"&&this.imageSection()}    
            </MDBRow>
            {this.state.actionType==="VIEW"&&this.buttonsSection()}
            
            {this.state.type ==="OFFICE"&&this.state.actionType==="VIEW"&&
            this.requestsTable()
            }
            
        </MDBContainer>
      </Spin>
    </div> 
  )
  }
}



const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(UserInfo)
);
